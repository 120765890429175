import React from "react"
import { SEO, Layout, Container } from "../components"

const FAQ = ({ location }) => {
  return (
    <Layout
      location={location}
      title="Frequently Asked Questions"
      subTitle="QUESTIONS"
    >
      <SEO
        title="Frequently Asked Questions"
        description="Collection of questions you might want to ask the Lightville team"
      />
      <Container>
        <p>Frequently Asked Questions</p>
      </Container>
    </Layout>
  )
}

export default FAQ
